// checkout
const version = sessionStorage.checkout_new_v1
if (version === 'old' || version === 'new') {
    console.log(`%c 命中强制测试开关；当前 checkout 版本：${version}；`, "background:yellowgreen;color:black;font-size:16px;padding: 4px;")
    console.log(`%c 测试落地页需确保设有cookie：checkout_new_v1=${version}；`, "background:yellowgreen;color:black;font-size:16px;padding: 4px;")
} else {
    console.log(`%c 命中apollo配置 checkout 版本；`, "background:yellowgreen;color:black;font-size:16px;padding: 4px;")
    sessionStorage.removeItem('checkout_new_v1')
}

window.switchCheckout = function (version) {
    if (version === 'old' || version === 'new') {
        console.log('%c 切换 checkout 版本：' + version, "background:yellowgreen;color:black;font-size:16px;padding: 4px;")
        console.log(`%c 测试落地页需确保设有cookie：checkout_new_v1=${version}；`, "background:yellowgreen;color:black;font-size:16px;padding: 4px;")
        sessionStorage.setItem('checkout_new_v1', version)
    } else {
        console.log('%c 恢复 checkout apollo 配置版本', "background:yellowgreen;color:black;font-size:16px;padding: 4px;")
        sessionStorage.removeItem('checkout_new_v1')
    }
    location.reload()
}
// checkoutend