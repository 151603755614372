/* globals _GB_PopUpModalEventCenter_,_quickRegisterEventCenter_,_GB_LoginEventCenter_,_eventCenter_ */

import xssEncode from 'xss'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { setCookie, getCookie, getQueryString, parseQueryString, getLocalStorage, setLocalStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { getIpCountrySetCache } from './utils/index.js'

window.gbIsJudgeIP = false

// 一些公共字段存入localStorage 对外使用
function initCommonLocalStorage() {
  const { lang, SiteUID, SERVER_TYPE } = gbCommonInfo || {}
  let localCountry = ''
  if (SERVER_TYPE === 'usa') localCountry = 'US'
  if (SERVER_TYPE === 'eur') localCountry = 'DE'
  setLocalStorage({ key: 'pwa_gb_common_info', value: {
    SiteUID,
    lang,
    localCountry,
  }, expire: 30 * 24 * 3600 * 1000 })
}

/** 原 common 中接口拉取及 cookie操作统一收敛 */
async function initCommonCookieManager() {
  const [userAbtData, countryData] = await Promise.all([getUserAbtData()], UserInfoManager.get({ key: 'Country', actionType: 'initCommonCookieManager' }))

  const { lang, SiteUID } = gbCommonInfo
  const { Movepoints } = userAbtData
  const { param } = Movepoints || {}
  const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'initCommonCookieManager' }) || ''
  const countryId = countryData?.countryId || ''
  const appCountry = countryData?.countryAbbr || ''
  const pwaCurrency = UserInfoManager.get({ key: 'currency', options: {onlyCookie: true}, actionType: 'common/cookieManager' })
  // const orid = getLocalStorage('originOtherId') // null，之前是undefined;
  const isEverLogined = localStorage.getItem(`${member_id}_${SiteUID}_isEverLogined`) || ''
  const isPointMigrate = isLogin() && param == 'type=B' && !isEverLogined
  const isDefaultCurrencyExpire = getLocalStorage(`pwa_default_currency_expire_${lang}`)
  // const href = document.location.href
  // const isSource = !orid && (href.indexOf('url_from') > -1 || href.indexOf('utm_source') > -1)
  
  Promise.all([
    checkIpJump({ countryId, appCountry, pwaCurrency }) ? ipJump() : Promise.resolve(),
    isPointMigrate ? pointMigrate({ SiteUID, member_id }) : Promise.resolve(),
    // isSource ? sourceUpdate({ href }) : Promise.resolve(),
    !isDefaultCurrencyExpire ? defaultCurrency() : Promise.resolve(),
    commonCookie()
  ])
}

/** ipJump */
function ipJump() {
  try {
    getIpCountrySetCache(function (countryData) {
      let privacy_acountryId = 0
      if (countryData?.countryId) {
        privacy_acountryId = countryData.countryId
      }
        
      window.gbIsJudgeIP = true
        
      // US站欧洲IP需要显示隐私政策
      setTimeout(function () {
        if (typeof _GB_PopUpModalEventCenter_ !== 'undefined') {
          _GB_PopUpModalEventCenter_.$emit('us-privacy-ip-welcome', privacy_acountryId)
        }
        if (typeof _quickRegisterEventCenter_ !== 'undefined') {
          _quickRegisterEventCenter_.$emit('us-privacy-ip-quickregister', privacy_acountryId)
        }
        if (typeof _GB_LoginEventCenter_ !== 'undefined') {
          _GB_LoginEventCenter_.$emit('us-privacy-ip-login', privacy_acountryId)
        }
        if (typeof _eventCenter_ !== 'undefined') {
          _eventCenter_.$emit('us-privacy-ip-checkout-login', privacy_acountryId)
        }
      }, 500)
    })
  } catch (e) {
    console.log(e)
  }

}

/** Migrate */
async function pointMigrate({ SiteUID, member_id }) {
  try {      
    localStorage.setItem(`${member_id}_${SiteUID}_isEverLogined`, true)
    schttp({
      url: `/autoMigrate`,
    }).then(res => {
      if (res.code == 0 && res.info) {
        if (res.info?.result != 1) {
          // 迁移失败，本地新增标志位记录调用失败，下次重启发起请求
          localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'error', time: new Date().getTime() }))
        } else {
          localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'success', time: new Date().getTime() }))
        }
      } else {
        localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'error', time: new Date().getTime() }))
      }
    }).catch(() => {
      localStorage.setItem(`${member_id}_${SiteUID}_pointMigrateFlag`, JSON.stringify({ status: 'error', time: new Date().getTime() }))
    })
  } catch(e) {
    console.log(e)
  }
}

/** 营销来源跟踪 */
// function sourceUpdate({ href }) {
//   schttp({
//     url: '/api/common/source/update',
//     params: {
//       urlcurrent: encodeURIComponent(href)
//     }
//   }).then(function (data) {
//     if (data.code == 0 && data.info != null) {
//       setLocalStorage({ key: 'originOtherId', value: data.info, expire: 30 * 24 * 3600 * 1000 })
//       if (typeof sa === 'function') {
//         sa('send', {
//           activity_name: 'expose_origin_otherid',
//           activity_param: {}
//         })
//       }
//     }
//   })
// }

/** 根据ip设置用户默认货币 */
function defaultCurrency() {
  if (!navigator.cookieEnabled || window !== window.top) return
  const { lang } = gbCommonInfo
  const { currency: urlCurrency } = parseQueryString(location.search) || ''
  schttp({ url: '/api/common/currency/get' }).then(res => {
    const { code, info = {} } = res
    if (code != 0) return
    const { currency = {} } = info || {}
    try {
      const nowCurrency = UserInfoManager.get({ key: 'currency', actionType: 'common/cookieManager' })
      const defaultCurrency = Object.keys(currency).find(k => currency[k].default == 1)
      
      const isHasCookieId = getCookie(`cookieId`) || ''

      localStorage.setItem(`currencyData_${lang}`, JSON.stringify(currency))
      setLocalStorage({ key: `pwa_default_currency_expire_${lang}`, value: 1, expire: 3600 * 1000 }) // 1h

      // url设置货币优先级最高，如果url有货币并且该站点支持该货币
      if (urlCurrency && currency[urlCurrency]) return

      //下面这段逻辑看起来会将cookie的货币改为默认货币，应该以cookie的为准，暂时不需要，先注释
      // 设置的默认货币与接口请求回来的默认不一样，取接口取回来的默认货币
      // if (defaultCurrency && defaultCurrency != nowCurrency && isHasCookieId) {        
      //   if (!getLocalStorage(`manualChangeCurrency_${lang}`)) { // 手动设置币种还在有效期, 不自动设置币种
      //     window.location.reload(true) // 强制重新刷新页面
      //   }
      // }
    } catch (e) {
      console.log(e)
    }  
  })
}

function commonCookie() {
  if (navigator.cookieEnabled) {
    var cookieId = getCookie('cookieId')
    if (!cookieId) {
      cookieId = window.$System.Math.IntUtil.genGUIDV4()
      // 根据逻辑：词出的true为curMainDomain 
      setCookie({ key: 'cookieId', value: cookieId, end: 400 * 24 * 60 * 60, curMainDomain: true })
    }
    const imgctn = document.createElement('div')
    imgctn.id = 'imgcontainer'
    imgctn.style.display = 'none'
    imgctn.innerHTML = '<img src="' + xssEncode(gbCommonInfo.langPath) + '/api/common/image/cookieId/get?cookieid=' + xssEncode(cookieId) + '" style="display:none;">'
    document.body.appendChild(imgctn)
  }
      
  const from_country = getQueryString({ key: 'from_country' })
  if (['ar', 'ar-en', 'in', 'uk', 'mx', 'hk', 'tw', 'vn', 'se', 'ma', 'maar', 'sg', 'za', 'co'].includes(from_country)) {
    setCookie({ key: 'mobileJump', value: '0' })
    setCookie({ key: `jump_to_m${from_country}`, value: '0', end: 3600 * 24 })
  }
}

function checkIpJump({ countryId, appCountry, pwaCurrency }) {
  return !countryId || !appCountry || !pwaCurrency
}

initCommonLocalStorage()
requestIdleCallback(() => {
  initCommonCookieManager()
})
