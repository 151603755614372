import { v4 as uuidv4 } from 'uuid'
import { parseQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
const { REPORT_URL, IS_RW, langPath, MDMP_USER_LANDING_SA_SEND_TIMING } = gbCommonInfo
const { MARKETING_URL } = REPORT_URL

// 广告投放分流埋点
const advertisingSendParams = adrtb => {
  if (IS_RW || (location.pathname != `${langPath}/global/marketing.html`)) return {}
  if (adrtb == 1) return { ad_abt_request: 1, ad_request_result: 'success' }
  if (adrtb == 2) return { ad_abt_request: 1, ad_request_result: 'timeout' }
  if (adrtb == 3) return { ad_abt_request: 1, ad_request_result: 'fail' }
  return { ad_abt_request: 0, ad_request_result: '' }
}

// 营销归因埋点发送:用户着陆
const sendMdmpUserLanding = () => {
  try {
    const { url_from = '', aff_id = '', onelink = '', scene = '', campaign = '', journey_name = '', deeplink = '', requestId = '', adrtb = 0 } = parseQueryString(location.search)
    if (url_from || aff_id || onelink || scene === 'onelink') {
      const reportUrl = `${MARKETING_URL}/mdmpUserLanding`
      const commonParams = {
        uuid: uuidv4(),
        onelink: onelink || journey_name,
        reffer: location.href,
        campaign,
        journey_name,
        scene,
        deeplink,
        channel: url_from,
        ...advertisingSendParams(adrtb)
      }

      // 命中营销投放的商详落地页分流实验
      const isHit = isHitDetailMarketingAnalyticsVerify()
      if (isHit) {
        // 发送 sa 埋点校验请求, 打点阶段 1
        schttp({
          url: '/api/analytics-verify',
          method: 'post',
          params: {
            type: 'mdmpUserLanding',
            stage: 1,
          },
        })
      }
      window.sa('error', reportUrl, {
        activity_name: 'Landing',
        requestId,
        ...commonParams
      }, {
        onRequest(data) {
          if (isHit) {
            // 发送 sa 埋点校验请求, 打点阶段 2
            schttp({
              url: '/api/analytics-verify',
              method: 'post',
              params: {
                type: 'mdmpUserLanding',
                stage: 2,
              },
              data,
            })
          }
        },
        onResponse(status, data) {
          if (isHit) {
            // 发送 sa 埋点校验请求, 打点阶段 3
            const options = {
              url: '/api/analytics-verify',
              method: 'post',
              params: {
                type: 'mdmpUserLanding',
                stage: 3,
                status,
              },
              data,
            }
            // 非 200 时代表上报出错, 需要记录下来
            if (!String(status).startsWith('2')) {
              options.params.badStatus = 1
            }
            schttp(options)
          }
        },
      })
      window.sa('send', {
        activity_name: 'click_marketing_url_landing',
        activity_param: {
          referer: encodeURIComponent(window.location.href),
          traffic_source: document.referrer || '',
          bu_department: 'marketing',
          ...commonParams
        }
      })
      // 错误要上报的
      if (onelink && SaPageInfo && SaPageInfo.page_name === 'page_other') {
        window.sa('error', `${MARKETING_URL}/mdmpMarketingWebReport`, {
          activity_name: 'click_onelink_web_error',
          activity_param: {
            bu_department: 'marketing'
          },
          bu_department: 'marketing',
          onelink: onelink || journey_name,
        })
      }
    }
  } catch (e) { console.log(e) }
}


function isHitDetailMarketingAnalyticsVerify() {
  if (typeof window === 'undefined') {
    return false
  }
  const ANALYTICS_VERIFY_SWITCH_CONDITION = gbCommonInfo?.ANALYTICS_VERIFY_SWITCH_CONDITION || {}
  const query = parseQueryString(location.search)
  return [...Object.entries(ANALYTICS_VERIFY_SWITCH_CONDITION)].filter(([, value]) => value).every(([key, value]) => query[key] === value)
}


if (MDMP_USER_LANDING_SA_SEND_TIMING !== 'on') {
  sendMdmpUserLanding()
}

