
// 声明式埋点通用解决方案
// 适用场景 同步点击、异步点击
import saModule from './sa'
import { debounce } from '@shein/common-function'
import { SILogger } from 'public/src/pages/common/monitor/index'

const SYNC_CLICK_EVENT = 'syncClick'

export default class DeclarativeAnalysis {
  // 命名空间
  NAMESPACE = 'DA-'
  init () {
    $(document.body).on('click', e => {
      const $target = $(e.target).closest(`[${this.NAMESPACE}type]`)
      const target = $target[0]

      // 发送渠道
      const triggerMode = this.getTrack($target)

      if (target) {
        requestIdleCallback(() => {
          const type = $target.attr(this.NAMESPACE + 'type')
          if (type == SYNC_CLICK_EVENT) {
            if (triggerMode.includes('sa')) {
              console.log('[DA 声明埋点]', '当前 track 方式不推荐使用，请及时更换')
              const params = this.getSaAttrs(target, ['name', 'param', 'beacon'])
              SILogger.logInfo({
                tag: 'da_declarative_existing_analysis',
                message: 'da declarative existing analysis',
                params
              })
              saModule.click(params)
            }
          }
        })
      }
    })
    $(window).scroll(
      debounce({
        func: () => {
          this.scrollFunc()
        },
        wait: 200
      })
    )
  }

  getTrack ($target) {
    // 发送渠道
    const triggerMode = []
    if ($target.attr(this.NAMESPACE + 'sa-name')) {
      triggerMode.push('sa')
    }
    return triggerMode
  }

  // 获取 sa 发送的属性
  getSaAttrs (target, attrs) {
    const $target = $(target)
    return attrs.reduce((curr, attr) => {
      const attrName = this.NAMESPACE + 'sa-' + attr
      const attrValue = $target.attr(attrName)
      if (attrValue) {
        curr[attr] = attrValue
      }
      return curr
    }, {})
  }

  scrollFunc () { // 普通曝光埋点
    const documentOffsetHeight = document.body.offsetHeight
    $(`[${this.NAMESPACE}view-type]`).each((i, element) => {
      var $this = $(element)

      if ($this.attr(`${this.NAMESPACE}view-type`) == 'ordinary' && $this[0].getBoundingClientRect().top + 15 <= documentOffsetHeight) {
        console.log('[DA 声明埋点]', '当前 track 方式不推荐使用，请及时更换')
        this.ordinaryView(element)
      }
    })
  }

  ordinaryView (element) {
    const $target = $(element)
    const triggerMode = []
    let viewNoParam = false // 曝光不需要参数
    if ($target.attr(this.NAMESPACE + 'sa-name')) {
      triggerMode.push('sa')
      viewNoParam = $target.attr(this.NAMESPACE + 'view-no-param')
    }
    if (triggerMode.includes('sa')) {
      const params = this.getSaAttrs(element, viewNoParam ? ['name'] : ['name', 'param'])
      SILogger.logInfo({
        tag: 'da_declarative_existing_analysis',
        message: 'da declarative existing analysis',
        params
      })
      saModule.view(params)
    }
    $(element).removeAttr(`${this.NAMESPACE}view-type`)
    $(element).attr('el-viewed', true)
  }
}
